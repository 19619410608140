import type { DefaultLook, SparkButtonProps } from './types';

type SizeDefinition = Record<SparkButtonProps['size'], string>;
type LookDefinition = Record<DefaultLook | string, string>;
type ShapeSizeDefinition = Record<SparkButtonProps['shape'], SizeDefinition>;

export const BASE_CSS =
  'transition-all font-medium inline-flex items-center justify-center select-none whitespace-nowrap focus-visible:outline-none leading-none';

export const ROUNDED_CSS: ShapeSizeDefinition = {
  rounded: {
    xs: 'rounded-md',
    sm: 'rounded-md',
    md: 'rounded-md',
    lg: 'rounded-lg',
  },
  pill: {
    xs: 'rounded-full',
    sm: 'rounded-full',
    md: 'rounded-full',
    lg: 'rounded-full',
  },
  circle: {
    xs: 'rounded-full',
    sm: 'rounded-full',
    md: 'rounded-full',
    lg: 'rounded-full',
  },
} as const;

const padding: SizeDefinition = {
  xs: 'px-2',
  sm: 'px-3',
  md: 'px-4',
  lg: 'px-5',
} as const;

export const PADDING_CSS: Record<'rounded' | 'pill', SizeDefinition> = {
  rounded: padding,
  pill: padding,
} as const;

const heightSize: SizeDefinition = {
  xs: 'h-5',
  sm: 'h-6',
  md: 'h-7',
  lg: 'h-10',
} as const;

export const HEIGHT_CSS: Record<'rounded' | 'pill', SizeDefinition> = {
  rounded: heightSize,
  pill: heightSize,
} as const;

const textSize: SizeDefinition = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-sm',
  lg: 'text-lg',
};

export const TEXT_SIZE_CSS: Record<'rounded' | 'pill', SizeDefinition> = {
  rounded: textSize,
  pill: textSize,
} as const;

export const WIDTH_HEIGHT_CSS: Record<'circle', SizeDefinition> = {
  circle: {
    xs: 'min-w-[1.25rem] min-h-[1.25rem]',
    sm: 'min-w-[1.75rem] min-h-[1.75rem]',
    md: 'min-w-[2.25rem] min-h-[2.25rem]',
    lg: 'min-w-[3rem] min-h-[3rem]',
  },
};

export const ICON_SIZE: SizeDefinition = {
  xs: '12',
  sm: '14',
  md: '16',
  lg: '20',
} as const;

export const UNDERLINE_CSS: Record<'text' | string, string> = {
  text: 'underline underline-offset-8 decoration-transparent hover:underline hover:underline-offset-4 hover:decoration-current',
} as const;

// see .radiate-effect CSS in SparkButton.vue
export const RADIATE_EFFECT_CSS: LookDefinition = {
  unfilled: 'radiate-effect',
  filled: `radiate-effect`,
  outlined: `radiate-effect`,
  text: '',
};
